.data-box {
    /* display: flex;
    flex-direction:column ;
    justify-content: space-between; */
}

.TopBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.Section-new {
    background: #fff;
    padding: 25px 50px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.Main-box-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.order-info-box {
    text-align: left;
    margin-bottom: 20px;
}

.detail-page-section {
    margin: 40px;
}

.order-text-1 {
    margin: 0;
    font-size: 16px;
}

.order-text-2 {
    margin: 0;
    color: #000;
    font-weight: 600;
    max-width: 350px;
}

.section-box-2 {
    background: #fff;
    /* / margin-top: 30px;  */
    padding: 12px 0 40px 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.Box-Upper-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #f2f2f2;
    padding: 0 40px;
}

.Inside-Box-2 {
    display: flex;
    align-items: center;
    gap: 100px;
    min-width: 156px;
    justify-content: space-between;
}

.Btn-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 20px;
}


.Button-box-1 {
    background: #11192a !important;
    color: #fff !important;
    cursor: pointer;
}

.Button-box-12 {
    background: #11192a !important;
    color: #fff !important;
    cursor: pointer;
    margin-top: 20px !important;
    margin-left: 15px !important;
}

.Button-box-1:focus {
    background: #11192a !important;
    color: #fff !important;
}

.button-text {
    margin: 0;
}

.Section-4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.section-4-right {
    display: flex;
    gap: 30px;
    align-items: center;
}

.section-5 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.driver-detail {}

.detail-1 {
    margin: 0;
}

.detail-2 {
    margin: 0;
    color: #000;
    font-weight: 600;
}

.driver-img {
    border-radius: 50%;
}

.info-text {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}

.pickup-text {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}

.order-info-box-right {
    text-align: right;
}

.Button-box-2 {
    background: blue !important;
    color: #fff !important;
    cursor: pointer;
}

.new-box-2 {
    display: flex;
    align-items: center;
    gap: 30px;
}

.Select-options {
    height: 40px;
    width: 140px;
    border-radius: 10px;
    background: transparent;
    padding: 0 10px;
    font-size: 17px;
    border: 1px solid #b1b3b4;
    color: #b1b3b4;
}

.Button-box-1234 {
    background: grey !important;
}


.TableHeading{
    font-weight: 600;
}