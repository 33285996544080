.avail-btn{
    min-width: 33%;
    padding: 8px !important;

}

.StatusLabel{
    margin-top: 15px !important;
}

.table-row-1{
    text-align: center;
}