.food-image{
    width: 20%;
    height: 105px;
    object-fit: cover;
}

.UpperBox{
    /* display: flex;
    align-items: baseline;
    gap: 30px;
    justify-content: space-between;
    min-width: 197px; */
}

.TextLabel{
    color: #11192a;
    font-size: 20px;
}

.Text1{
    color: #ff1e46;
    font-size: 17px;
}

.MainBox{
    background: rgb(255, 255, 255);
    padding: 15px;
    margin: 30px;
    border-radius: 10px;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}

.Section-1{
    display: flex;
    
    align-items: baseline;
    gap: 185px;

}


.info-text {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 10px;
}

.Section-new {
    background: #fff;
    padding: 25px 50px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.Main-box-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.order-info-box {
    text-align: left;
    margin-bottom: 20px;
}

.order-text-1 {
    margin: 0;
    font-size: 16px;
}

.order-text-2 {
    margin: 0;
    color: #000;
    font-weight: 600;
}

.order-info-box-right {
    text-align: right;
}

.back-btn{
    background: blue !important;
    color: #fff !important;
    margin-top: 20px !important;
}


.food-image22{
    width: 115px !important;
    height: 115px !important;
}

.detail-page-section{
    margin: 40px;
}


.back-btn-2{
    background: blue !important;
    color: #fff !important;
    margin-top: 10px !important;
    margin-bottom: 20px !important;
}