.StatusClass{
    margin-top: 15px;
}

.OrderBtn{
    background: blue !important;
    color: #fff  !important;
    width: 100%  !important;
    margin-bottom: 15px  !important;
    height: 50;
    min-width: 157px !important;
}

.sectin-1-right{
    display: flex;
    width: 100%;
    gap: 10px;
    display: flex;
    justify-content: space-between;
}


.orderBtnBox{
    /* width: 240px; */
    display: flex;
    align-items: center;
    gap: 20px;
}

.section-Header{
    justify-content: space-between !important;
    position: sticky;
    width: 100%;
    background: #fff;
}

.table-box{
    /* margin-top: 110px; */
    height: 350px;
}

.table-data-1{
    max-width: 200px !important;
}

.select-box{
    width: 155px !important;
}