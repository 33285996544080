.popBox{
    /* width: 30% !important;
    left: 904px !important;
    top: 45px !important; */
    /* / height: 500px !important; / */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px !important;

}

.Notification-list{
    background: #233865 !important;
    color: #fff !important;
}

.Notification-text{
    color: #fff;
    cursor: pointer;
}

.Notification-text2{
    color: #fff;
    
}
.text-box1{
    border-bottom: 1px solid #fff;
    margin-bottom: 10px;
    align-items: center;
    margin-left: -16px;
    margin-right: -15px;
    padding: 0 12px;
    gap:50px;
}

.Notifcation-section-box{
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 99;
    border: 1px solid #233865;
    width: 100%;
    border-radius: 5px;
}

.list-box-1{
    max-height: 500px;
}


.View-box{
    display: flex;
    align-items: center;
    gap: 5px;
}

.view-icon{
    color: #fff;
}