.Top-Box-Section{
    display: flex;
    align-items: center;
    gap: 30px;
}


.choose-file1{
    border: 1px solid #c4c4c4;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 10px;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.image-section-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.Edit-Rider-Image{
    height: 230px;
    width: 200px;
}

.SubmitBtn11{ 
    background: #5569ff !important;
    color: #fff !important;

}