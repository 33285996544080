.UpperBox{
    display: flex;
    gap: 20px;
}

.Btn1{
    background: #5569ff !important;
    color: #fff !important;
    
    font-size: 12px !important;

}

.BtnBox{ 
    margin-top: 20px;
    display: flex;
    gap: 20px;
    align-items: center;
}